<template>
	<div id="app">
		<Navigation style="z-index='9999'" />
		<router-view></router-view>
		<Float style="z-index='9999'"/>
		<Foot />
	</div>
</template>

<script>
	import Navigation from './components/navigation.vue'
	import Foot from './components/foot.vue'
	import Float from './components/float.vue'
	export default {
		name: 'App',
		components: {
			Navigation,
			Foot,
			Float
		},
		data() {
			return {

			}
		},
		created() {

		},
		updated() {

		},
		methods: {

		}
	}
</script>

<style>
	body {
		margin: 0;
	}

	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		color: #2c3e50;
		margin: 0;
		padding: 0;
		position: relative;
	}
</style>