<template>
	<div class="content-container">
		<div class="foot-list">
			<div class="foot-item" v-for="(item,index) in list" :key="index">
				<div class="foot-title">{{item.title}}</div>
				<div class="foot-value" v-for="(items,index) in item.children" :key="index" @click="isTo(items)">
					{{items.name}}</div>
			</div>
			<div class="foot-link">
				<img :src="mp_image" class="link">
				<div>客服微信</div>
			</div>
			<!-- <div class="foot-link">
				<img :src="miniprogram_image" class="link">
				<div>小程序</div>
			</div> -->
		</div>
		<div class="tag">
			<span class="tag1">{{beian}}</span>
		</div>
		<div class="foot-listm">
			<el-collapse v-model="activeNames">
				<el-collapse-item :title="item.title" :name='index' v-for="(item,index) in list" :key="index">
					<div v-for="(items,index) in item.children" :key="index" class="foot-itemm" @click="isTo(items)">
						{{items.name}}</div>
				</el-collapse-item>
			</el-collapse>
		</div>
		<div class="linkm">
			<div class="foot-linkm">
				<img :src="mp_image" class="link">
				<div>客服微信</div>
			</div>
			<!-- <div class="foot-linkm">
				<img :src="miniprogram_image" class="link">
				<div>小程序</div>
			</div> -->
		</div>
		<div class="tagm">{{beian}}</div>
	</div>
</template>

<script>
	import Bus from '@/utils/EventBus.js'
	import * as indexs from '@/api/index.js'
	import {
		Number
	} from 'core-js/web'
	export default {
		name: 'floot-bottom',
		data() {
			return {
				activeNames: null,
				list: [{
					title: '万合大数据',
					children: [{
						name: '案例',
						url: '/case',
						num: '2'
					}, {
						name: '关于我们',
						url: '/about',
						num: '4'
					}, {
						name: '合作咨询'
					}]
				}, {
					title: '行业解决方案',
					children: []
				}],
				mp_image: '',
				miniprogram_image: '',
				beian: ''
			}
		},
		mounted() {
			this.footer()
		},
		methods: {
			//获取底部信息
			footer() {
				indexs.footer().then(res => {
					this.list[1].children = res.industry
					this.mp_image = res.mp_image
					this.miniprogram_image = res.miniprogram_image
					this.beian = res.beian
					this.$store.state.mobiles = res.service_mobiles[0]
					this.$store.state.vxImg = res.service_qrcode_image
					this.$store.state.logoImg = res.logo
					Bus.$emit('isPhone', {
						vxImg: res.service_qrcode_image,
						mobiles: res.service_mobiles[0]
					})
					Bus.$emit('isLogo', {
						logoImg: res.logo
					})
				})
			},
			//点击底部跳转
			isTo(e) {
				var num = 0
				if (e.url) {
					this.$router.push({
						path: e.url
					})
					num = e.num
				} else {
					if (e.id) {
						this.$router.push({
							path: '/serves?id=' + e.id
						})
						num = '1'
					} else {
						return
					}
				}
				this.$store.commit("isNum", num)
				Bus.$emit('setListInfo', num)
			}
		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang='less'>
	@media screen and (min-width: 750px) {
		.content-container {
			max-width: 1200px;
			margin: 0 auto;

			.foot-listm {
				display: none;
			}

			.tag-m {
				display: none;
			}

			.linkm {
				display: none;
			}

			.foot-list {
				display: flex;
				justify-content: space-between;
				width: 100%;
				padding: 49px 0;
				border-top: 1px solid #e5e5e5;
				border-bottom: 1px solid #e5e5e5;

				.foot-item {
					display: flex;
					flex-direction: column;

					.foot-title {
						margin-bottom: 33px;
						font-size: 20px;
						font-family: PingFangSC-Medium, PingFang SC;
						font-weight: 500;
						color: #47495a;
						line-height: 28px;
					}

					.foot-value {
						line-height: 20px;
						margin-bottom: 10px;
						cursor: pointer;
						color: #337ab7;
					}

					.foot-value:hover {
						text-decoration: underline;
					}
				}

				.foot-link {
					.link {
						width: 156px;
						height: 156px;
					}
				}
			}

			.tag {
				text-align: left;
				line-height: 40px;
				font-size: 12px;
				font-weight: 400;
				color: #999;

				.tag1 {
					margin-right: 86px;
				}

				.tag2 {
					cursor: pointer;
				}

				.tag3 {
					cursor: pointer;

					margin-left: 16px;
				}

				.tag2:hover {
					text-decoration: underline;
				}

				.tag3:hover {
					text-decoration: underline;
				}
			}
		}
		.tagm{
			display: none;
		}
	}

	@media screen and (max-width: 750px) {
		.foot-list {
			display: none;
		}

		.tag {
			display: none;
		}

		.foot-listm {
			border-top: 1px solid #f8f8f8;
			margin-top: 30px;
			padding: 30px 30px 30px;
			text-align: left;
			font-size: 30px;

			.foot-itemm {
				color: #84899d;
				padding: 8px 0;
			}
		}
		
		::v-deep .el-collapse-item__header{
			padding: 10px 0;
			font-size: 20px;
		}
		
		::v-deep .el-collapse{
			border-top: none;
		}

		.linkm {
			margin: 0 50px;
			display: flex;
			justify-content: center;
			
			.foot-linkm {
				margin-bottom: 20px;
				.link {
					width: 200px;
					height: 200px;
				}
				div{
					margin-top: 10px;
					font-size: 18px;
					color: #202020;
					font-weight: 500;
				}
			}
		}
		
		.tagm{
			text-align: center;
			padding: 10px;
			border-top: 1px solid #f8f8f8;
			font-size: 14px;
			color: #303030;
		}
	}
</style>