import request from "@/utils/request"; //导入封装请求的js文件

//获取服务二级
export function industry(params) {
	return request({
		url: "index/industry", //接口路径
		method: "get", //接口方法
		params: params //接口参数
	});
}

//获取底部页脚信息
export function footer(params) {
	return request({
		url: "index/footer", //接口路径
		method: "get", //接口方法
		params: params //接口参数
	});
}

//案例筛选tab
export function projectType(params) {
	return request({
		url: "index/projectType", //接口路径
		method: "get", //接口方法
		params: params //接口参数
	});
}

//案例列表
export function projectList(params) {
	return request({
		url: "index/projectList", //接口路径
		method: "get", //接口方法
		params: params //接口参数
	});
}

//服务详情
export function industryRow(params) {
	return request({
		url: "index/industryRow", //接口路径
		method: "get", //接口方法
		params: params //接口参数
	});
}

//案例详情
export function project(params) {
	return request({
		url: "index/project", //接口路径
		method: "get", //接口方法
		params: params //接口参数
	});
}

//意见分类列表
export function intention(params) {
	return request({
		url: "index/intention", //接口路径
		method: "get", //接口方法
		params: params //接口参数
	});
}

//在线咨询表单
export function consult(params) {
	return request({
		url: "index/consult", //接口路径
		method: "get", //接口方法
		params: params //接口参数
	});
}

//获取公司信息
export function location(params) {
	return request({
		url: "index/location", //接口路径
		method: "get", //接口方法
		params: params //接口参数
	});
}