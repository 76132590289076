//1.导入VueRouter
import Vue from "vue";
import VueRouter from 'vue-router'
//2.使用路由
Vue.use(VueRouter);
let originPush = VueRouter.prototype.push; //备份原push方法

VueRouter.prototype.push = function(location, resolve, reject) {
	if (resolve && reject) { //如果传了回调函数，直接使用
		originPush.call(this, location, resolve, reject);
	} else { //如果没有传回调函数，手动添加
		originPush.call(this, location, () => {}, () => {});
	}
}
//3.创建VueRouter的实例
const router = new VueRouter({
	//tips:不想要 #（锚点）就添加下面代码
	// mode: 'history',
	//4.配置路由的path和组件
	routes: [{
			path: "/",
			name: 'home',
			component: () => import('../view/index/index.vue'),
		},
		{
			path: "/case",
			name: 'case',
			component: () => import('../view/case/case.vue'),
		},
		{
			path: "/about",
			name: 'about',
			component: () => import('../view/about/about.vue'),
		},
		{
			path: "/scheme",
			name: 'scheme',
			component: () => import('../view/scheme/scheme.vue'),
		},
		{
			path: "/serves",
			name: 'serves',
			component: () => import('../view/serves/serves.vue'),
		},
		{
			path: "/info",
			name: 'info',
			component: () => import('../view/serves/info/info.vue'),
		}
	]
})

router.afterEach((to, from, next) => {
	var num = '0'
	if (to.name == 'home') {
		num = '0'
	} else if (to.name == 'serves') {
		num = '1'
	} else if (to.name == 'case') {
		num = '2'
	} else if (to.name == 'scheme') {
		num = '3'
	} else if (to.name == 'about') {
		num = '4'
	} else if (to.name == 'info') {
		num = '2'
	} else {
		return
	}
	window.scrollTo(0, 0)
})

//5.导入路由实例
export default router