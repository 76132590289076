<!-- 浮动 -->
<template>
	<div class="boxcss">
		<!-- <div class="icon-wrap contact">
			<img src="https://www.yhbit.cn/_nuxt/img/eeab353.png" class="icon contact">
		</div> -->
		<div class="icon-wrap box1" @click="callPhone()">
			<img src="https://www.yhbit.cn/_nuxt/img/0090ba6.png" class="icon">
			<div class="phone">
				{{mobiles.name}}: {{mobiles.mobile}}
			</div>
		</div>
		<div class="icon-wrap box2">
			<img src="https://www.yhbit.cn/_nuxt/img/0a33a05.png" class="icon">
			<img :src="vxImg" class="wechat">
		</div>
	</div>
</template>

<script>
	import Bus from '@/utils/EventBus.js'
	export default {
		data() {
			return {
				mobiles: this.$store.state.mobiles,
				vxImg: this.$store.state.vxImg
			}
		},
		created() {
			Bus.$on('isPhone', (res) => {
				this.vxImg = res.vxImg
				this.mobiles = res.mobiles
			})
		},
		methods: {
			callPhone() {
				if(this.isMobile()){
					window.location.href = 'tel://' + this.mobiles.mobile
				}else{
					
				}
			},
			isMobile() {
				let flag = navigator.userAgent.match(
					/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
					)
				return flag
			}
		}
	}
</script>

<style scoped lang="scss">
	.boxcss {
		position: fixed;
		top: 50%;
		right: 2px;
		transform: translateY(-50%);
		z-index: 99999;

		.icon-wrap {
			position: relative;
			margin-bottom: 10px;
			display: flex;
			justify-content: space-between;

			.phone {
				display: none;
				position: absolute;
				right: 80px;
				top: 0;
				width: 200px;
				height: 50px;
				padding: 20px;
				font-size: 16px;
				color: #000;
				border-radius: 5px;
				box-shadow: 2px 2px 5px #ccc;
				background: #fff;
			}

			.wechat {
				display: none;
				position: absolute;
				right: 80px;
				top: 0;
				width: 200px;
				height: 200px;
			}
		}

		@media screen and (min-width: 750px) {
			.box1:hover .phone {
				display: flex;
				align-items: center;
			}
		}

		.box2:hover .wechat {
			display: inline-block;
		}

		.icon {
			width: 70px;
			height: 70px;
			display: inline-block;
			background-repeat: no-repeat;
			background-position: 50%;
			background-size: 100%;
			vertical-align: middle;
		}
	}
</style>