// store/index.js
import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

// new一个Vuex实例并暴露出去
export default new Vuex.Store({
	//配置vuex-persistedstate
	plugins: [createPersistedState(
		//配置将vuex的状态储存到sessionStorage中（默认储存到localStorage中）
		{
			storage: window.sessionStorage
		}
	)],
	state: {
		num: 0,
		mobiles: {},
		vxImg: '',
		logoImg: ''
	},
	// 1.mutations中第一个参数永远都是自身的state，后面的参数都是形参
	mutations: {
		// 2.第二个位置的step是我们传递的第一个参数，有其他参数还可以在后面加
		isNum(state, step) {
			state.num = step
		}
	}
})