<template>
	<div class="content-container">
		<a>
			<img :src="logoImg" class="image">
		</a>
		<div class="button-box">
			<div class="item" v-for="(item,index) in list" :key="index" @mouseover="mouseover(item)"
				@mouseleave="mouseLeave(item)" @click="jump(item,index)">
				{{item.name}}
				<div class="childrenList" v-if="item.children&&item.show">
					<div class="List-item" v-for="(itemn,indexn) in item.children" :key="indexn"
						@click.stop="childrenclick(itemn.id)">
						{{itemn.name}}
					</div>
				</div>
				<div class="bottom" v-if="changeIndex==index||item.hover"></div>
			</div>
		</div>
		<div class="right-button" @click="showIng = !showIng">
			<img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAAAXNSR0IArs4c6QAAAohJREFUeF7tmkFqG0EUBaeZG3iXkDtkIYG2uUZykBzCB7Gv4a1AWuQOId7lBmKCIFkIpMB/lOZjU17rvW9VqXtaqMfkXyuB0Trd4ZMCmj8EClBAM4Hm8a4ABTQTaB7vClBAM4Hm8a4ABTQTaB7vClBAM4Hm8a4ABTQTaB7vCniLAna73afT6fS4LMuXaZo+NL+H7vGvY4yXeZ6/7/f7n9V/prwC/sL/sSzLQ3XYe379GOP3PM+fqxLKArbb7dOyLF/fM8z0vY0xng+Hw7dKvixgs9n8ctu5ifj1eDx+VECFAPva+wtwC7ptbJUtyIfwdQGrPYTP4z2GXkhY9xjKbpm2lU9BImMJKIDlWW5TQBkZG1AAy7PcpoAyMjagAJZnuU0BZWRsQAEsz3KbAsrI2IACWJ7lNgWUkbEBBbA8y20KKCNjAwpgeZbbFFBGxgYUwPIstymgjIwNKIDlWW5TQBkZG4gE+KP8hYR1f5T3Wsr1FbDatRQvZt3egla5mOXd0P8+A+5/NVEBzQLcgpq3IB/CzQ/h83iPoY3HUPZriG3RFzGxcQQUwLGMmhQQYeNCCuBYRk0KiLBxIQVwLKMmBUTYuJACOJZRkwIibFxIARzLqEkBETYupACOZdSkgAgbF1IAxzJqUkCEjQspgGMZNSkgwsaFFMCxjJoUEGHjQpEAf5S/EODdUO7zmDd5NzRnhyW9G4qhjIu8GxqjY4L3F+Dd0NumVtmCvBt6XcBqD+HzeI+hjcdQZqu05R+B6IuY+DgCCuBYRk0KiLBxIQVwLKMmBUTYuJACOJZRkwIibFxIARzLqEkBETYupACOZdSkgAgbF1IAxzJqUkCEjQspgGMZNSkgwsaF/gD96uRhXVGGtQAAAABJRU5ErkJggg=="
				alt="">
		</div>
		<transition name="fadeHeight">
			<div class="button-boxm" v-if="showIng">
				<div class="boxm-item" v-for="(item,index) in list" :key="index" @mouseover="mouseover(item)"
					@mouseleave="mouseLeave(item)">
					<div :style="{'color':changeIndex==index||item.hover?'#2648ef':'#555'}" @click="jump(item)">
						{{item.name}}
					</div>
					<div v-if="item.children&&item.show">
						<div class="boxm-children" v-for="(itemn,indexn) in item.children" :key="indexn"
							@click.stop="childrenclick(itemn.id)">
							{{itemn.name}}
						</div>
					</div>
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
	import Bus from '@/utils/EventBus.js'
	import * as indexs from '@/api/index.js'
	export default {
		name: 'content-container',
		data() {
			return {
				showIng: false,
				changeIndex: this.$store.state.num,
				logoImg: this.$store.state.logoImg,
				list: [{
						name: '首页',
						hover: false,
						show: false,
						url: '/'
					},
					{
						name: '服务',
						hover: false,
						show: false,
						url: '/serves',
						children: []
					},
					{
						name: '案例',
						hover: false,
						show: false,
						url: '/case'
					},
					{
						name: '方案论',
						hover: false,
						show: false,
						url: '/scheme'
					},
					{
						name: '关于我们',
						hover: false,
						show: false,
						url: '/about'
					}
				],
				url: '',
				num: ''
			}
		},
		created() {
			this.industry()
			Bus.$on("setListInfo", (res) => {
				this.changeIndex = res
			})
			Bus.$on('isLogo', (res) => {
				console.log(res);
				this.logoImg =  res.logoImg
			})
		},
		methods: {
			industry() {
				indexs.industry().then(res => {
					this.list[1].children = res.list
				})
			},
			mouseover(item) {
				item.hover = true
				if (item.children) {
					item.show = true
				}
			},
			mouseLeave(item) {
				item.hover = false
				item.show = false
			},
			jump(item, index) {
				if (item.children) {
					item.show = true
					this.url = item.url
					this.num = index
				} else {
					this.$router.push({
						path: item.url
					})
					this.changeIndex = index
					this.showIng = false
				}
				this.$store.commit("isNum", index.toString())
			},
			childrenclick(e) {
				this.$router.push({
					path: '/serves?id=' + e,
				})
				this.changeIndex = this.num
				this.showIng = false
				this.$store.commit("isNum", '1')
			}
		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang='less'>
	@media screen and (min-width: 750px) {
		.content-container {
			position: sticky;
			top: 0;
			height: 66px;
			display: flex;
			justify-content: space-evenly;
			margin: 0 auto;
			width: 100%;
			background: #fff;
			z-index: 9999;
			box-shadow: 0 2px 8px 0 rgba(26, 66, 70, .1);

			.image {
				margin-top: 20px;
				/* width: 125px; */
				height: 33px;
			}

			.button-box {
				display: flex;
				align-items: center;
				cursor: pointer;

				.item {
					position: relative;
					padding: 0 20px;
					height: 66px;
					display: flex;
					align-items: center;

					.childrenList {
						text-align: left;
						z-index: 99;
						width: 150px;
						position: absolute;
						top: 70px;
						left: 0;
						background: #fff;
						padding: 20px;

						.children-title {
							padding: 16px 0;
						}

						.List-item {
							padding: 8px 0;
						}

						.List-item:hover {
							text-decoration: underline;
						}
					}

					.bottom {
						position: absolute;
						left: 50%;
						bottom: 0;
						transform: translateX(-50%);
						content: "";
						display: inline-block;
						width: 30px;
						height: 6px;
						background: #2648ef;
						border-radius: 3px;
					}
				}
			}

			.right-button {
				display: none;
			}

			.button-boxm {
				display: none;
			}
		}
	}

	@media screen and (max-width: 750px) {
		.content-container {
			position: sticky;
			top: 0;
			/* height: 50px; */
			display: flex;
			justify-content: space-between;
			z-index: 9999;
			width: 100vw;
			padding: 15px 0;
			overflow: hidden;
			background-color: #f8f8f8;
			border-bottom: 2px solid #e7e7e7;

			.image {
				margin-top: 10px;
				/* width: 100px; */
				height: 26px;
				margin-left: 25px;
			}

			.button-box {
				display: flex;
				align-items: center;
				display: none;

				.item {
					position: relative;
					padding: 0 30px;
					height: 50px;
					display: flex;
					align-items: center;

					.bottom {
						position: absolute;
						left: 50%;
						bottom: 0;
						transform: translateX(-50%);
						content: "";
						display: inline-block;
						width: 30px;
						height: 6px;
						background: #2648ef;
						border-radius: 3px;
					}
				}
			}

			.right-button {
				margin-right: 20px;
				display: flex;
				align-items: center;

				img {
					width: 35px;
					height: 35px;
				}
			}

			.button-boxm {
				position: fixed;
				top: 73px;
				width: 100%;
				background: #fff;
				text-align: left;

				.boxm-item {
					padding: 10px 15px;
					font-size: 14px;
					font-size: 14px;
					text-decoration: none;
					cursor: pointer;

					.boxm-children {
						margin-top: 10px;
						margin-left: 10px;
					}

					.boxm-children:hover {
						text-decoration: underline;
					}
				}
			}
		}
	}

	.fadeHeight-enter-active,
	.fadeHeight-leave-active {
		transition: all 1s;
		opacity: 1;
	}

	.fadeHeight-enter,
	.fadeHeight-leave-to {
		transition: all 1s;
		opacity: 0;
	}
</style>